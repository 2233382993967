<template>
  <div>
    <a-spin v-if="!showModel" :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <!-- 搜索栏 -->
        <a-row>
          <a-form-item label="所属影院" name="cinemaName" class="ui-form__item">
            <a-input v-model:value="formState.cinemaName" placeholder="请输入所属影院"></a-input>
          </a-form-item>

          <a-form-item label="状态" name="isDisabled" class="ui-form__item">
            <a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear style="width: 180px;">
              <a-select-option :value="0">启用</a-select-option>
              <a-select-option :value="1">禁用</a-select-option>
            </a-select>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <a-button v-permission="['cinema_policy_refundTicket_add']" type="primary" @click="onAdd">新增</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button
                style="margin-right: 10px"
                type="primary"
                html-type="submit"
            >搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>

      </a-form>

      <div style="margin-top: 20px;">
        <a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
                 :scroll="{ x: 950 }">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'isDisabled'">
              <a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
              <a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
            </template>
            <template v-if="column.key === 'action'">
              <a-dropdown :trigger="['click', 'hover']">
                <a-button>操作 <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div v-permission="['cinema_policy_refundTicket_edit']">
                      <a-menu-item @click="toEdit(record)">
                        编辑
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_policy_refundTicket_open']" v-if="record.isDisabled">
                      <a-menu-item @click="onDisabled(record)">
                        启用
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_policy_refundTicket_close']" v-else>
                      <a-menu-item @click="onDisabled(record)">
                        禁用
                      </a-menu-item>
                    </div>
                    <div v-permission="['cinema_policy_refundTicket_del']">
                      <a-menu-item @click="onDelete(record)">
                        删除
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>
  </div>
  <temp v-if="showModel" :id="id" :isEdit="isEdit" @back="onBack"></temp>
</template>

<script>
import temp from '@/views/cinema/policy/refundTicket/refundTicket.vue';
import {
  Icon
} from '@/components/icon/icon.js';
import { deleteRefundPolicy, getRefundPolicyList, updateRefundPolicy } from "@/service/modules/cinema";
export default {
  components: {
    Icon,
    temp
  },
  data() {
    return {
      searchData: {},
      showModel: false,
      isEdit: false,
      isSee: false,
      id: 0,
      loading: false,
      formState: {},
      columns: [{
        title: '所属影院',
        dataIndex: 'cinemaName',
      }, {
        title: '规则名称',
        dataIndex: 'refundName',
      }, {
        title: '优先级',
        dataIndex: 'refundLevel',
      }, {
        title: '状态',
        key: 'isDisabled',
      }, {
        title: '操作',
        key: 'action',
        width: 150
      }],
      list: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
    this.getData();
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    onBack(isRef) {
      this.showModel = false;
      if (isRef) {
        this.$nextTick(() => {
          this.getData();
        })
      }
    },
    onAdd() {
      this.showModel = true;
      this.isEdit = false;
    },
    toEdit(item) {
      this.showModel = true;
      this.isEdit = true;
      this.id = item.id;
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getRefundPolicyList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onDisabled(item) {
      this.$confirm({
        title: '提示',
        content: '确定' + (item.isDisabled ? '启用' : '禁用') + '该政策吗？',
        onOk: async () => {
          let ret;
          this.loading = true;
          try {
            ret = await updateRefundPolicy({
              id: item.id,
              isDisabled: (item.isDisabled ? 0 : 1) + ''
            });
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
    onDelete(item) {
      this.$confirm({
        title: '提示',
        content: '确认要删除吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await deleteRefundPolicy({
              id: item.id
            })
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
